import React, { Component } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 245, 245);
`;

class HelpText extends Component{
  render() {
    return (
      <TextContainer>
        <Paper elevation={3} style= {{ padding: 20, display: 'inline-block'}}>
        <Typography variant="body1">
          All the rental websites in Australia will display the rent in per week.
          Use this to calculator to calculate the Monthly rent from the weekly rent.
          <br></br>
          <br></br>
          Formula used to calculate is 
          <br></br>
          Monthly rent = ((weekly rent / 7) * 365) / 12 
        </Typography>
          
         
         </Paper>
      </TextContainer>
    );
  }
}

export default HelpText;
