import React, { Component } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const THeader = styled.header`
    box-sizing: border-box;
    width: 100%;
    height: 8em;
    display: flex;
    align-itme: center;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
    background-color: rgb(192, 45, 26);
    color: #fff;
`;

class TitleHeader extends Component{
  render() {
    return (
      <THeader>
        <Typography variant="h4">Rent Calculator Australia</Typography>
      </THeader>
    );
  }
}

export default TitleHeader;
