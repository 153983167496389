import React, { Component } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const RentValue = styled.div`
  padding: 1em;
`;

const Result = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: column;
`;

function numberWithCommas(x) {
  if( x === undefined) {
    return "0"
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

class RentInput extends Component {

  constructor(props) {
    super(props);
    this.state = {value: 0};

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = async function(event) {
    await this.setState({value: parseInt(event.target.value, 10)});

    if(isNaN(this.state.value)){
      await this.setState({value: 0});
    }

    const weeklyRent = this.state.value || 0;
    const dailyRent = weeklyRent / 7;

    const monthlyValue = (dailyRent * 365)/12;
    this.setState({monthlyValue: monthlyValue.toFixed(2)});
  }

  render() {
    return (
      <div>
          <TextField 
          type="number"
          value={this.state.value} 
          onChange={this.handleChange}
          style={{backgroundColor: "white"}} 
          id="outlined-basic" 
          label="Weekly Rent" 
          variant="outlined" />
          { this.props.secondary ? 
            <CloseIcon style={{ marginLeft:"8px", marginBottom:"8px"}}  color="primary" onClick={this.props.onClose}></CloseIcon> : null }
          
          <Result>
          <Typography style={{ color: 'black'}} variant="h6">Your Monthly Rent is:</Typography>
            
          <RentValue><Typography style={{ color: 'black' }} variant="h6">$ {numberWithCommas(this.state.monthlyValue)} </Typography></RentValue>
          </Result>
      </div>
      );
    }
  }

  export default RentInput;