import React from 'react';
import './App.css';
import RentInput  from "./components/RentInput";
import TitleHeader from "./components/TitleHeader";
import HelpText from "./components/HelpText";
import StickyFooter from "./components/StickyFooter";
import CompareButton from "./components/CompareButton";

function App() {

  const [showCompare, setShowCompare] = React.useState(false)

  const onClick = () => setShowCompare(true)
  const onClose = () => setShowCompare(false)

  return (
    <div className="App">
      <TitleHeader/>  
      <HelpText/>
      <div className="App-body">
        <RentInput/> 
          { showCompare ? <RentInput secondary={true} onClose={onClose} /> : <CompareButton onClick={onClick} /> }      
      </div>
      <StickyFooter />
    </div>
  );
}
export default App;
