import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 3em;
  left: 5em;  
`;

class CompareButton extends Component {

  render() {
    return (
      <ButtonContainer>
      <Button variant="contained" color="secondary" style={{ backgroundColor: "#c02d1a"}} onClick={this.props.onClick}>
        Compare </Button>
      </ButtonContainer>  
    );
  }

}

export default CompareButton;